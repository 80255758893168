#root {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}

html body {
  background-color: #e7ebee;
}

.imgWrap {
  background: url(/static/media/Login-background.8bb542fe.jpg) no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
}

a {
  text-decoration: none;
  color: inherit;
}

a.link {
  text-decoration: underline;
  color: #6ae9c5;
}

